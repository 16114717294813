import React from 'react'
import { func, objectOf, any, bool } from 'prop-types'
import Modal from 'react-modal'
import styled from 'styled-components'
import { breakPoints, colors, fonts } from '@constants/styles'
import CloseButton from '@shared/close-button'

const StyledModal = styled(Modal)`
  color: ${colors.darkGrey};
  p {
    line-height: 1.25;
  }
  &:focus {
    outline: none;
  }
  .close-modal {
    position: absolute;
    top: -2em;
    right: -1.75em;
    background: ${colors.primary};
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    .close {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 2.5rem;
      height: 2.5rem;
      margin: auto;
      background-size: 100% !important;
    }
  }
  .modal-content {
    background-color: ${colors.lighterGrey};
    border: solid ${colors.lightGrey} 0.4rem;
    width: 70%;
    position: fixed;
    right: auto;
    bottom: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 45rem;
    max-width: 45rem;
    padding: 0.5rem;
    @media only screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
      max-height: 90%;
    }
    @media only screen and (max-width: ${breakPoints['large-max']}) {
      min-width: 10rem;
      width: 90%;
      max-height: 90%;
    }
    div {
      font-size: ${fonts.txtXsmall};
      line-height: 1.2;
      @media only screen and (min-width: ${breakPoints.medium}) {
        font-size: ${fonts.txtSmall};
      }
    }
    strong {
      font-weight: 600;
    }
    p {
      font-size: ${fonts.txtXsmall};
      padding-bottom: 0.75rem;
      @media only screen and (min-width: ${breakPoints.medium}) {
        font-size: ${fonts.txtSmall};
      }
      @media only screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        padding-bottom: 0.5rem;
      }
      b {
        font-weight: 600;
        font-size: ${fonts.txtSmall};
        @media only screen and (min-width: ${breakPoints.medium}) {
          font-size: ${fonts.txtMedium};
        }
      }
    }
  }
`

Modal.setAppElement('#___gatsby')

const CreditModal = ({ promoMessage, modalOpen, closeModal }) => (
  <StyledModal
    isOpen={modalOpen}
    onRequestClose={closeModal}
    contentLabel="Rooms To Go Finance Details"
    className="rtg-credit-modal"
    overlayClassName="modal-overlay"
  >
    <div className="modal-content">
      {promoMessage && promoMessage.childMarkdownRemark && promoMessage.childMarkdownRemark.html && (
        <div dangerouslySetInnerHTML={{ __html: promoMessage.childMarkdownRemark.html }} />
      )}
      <CloseButton closeModal={closeModal} />
    </div>
  </StyledModal>
)

CreditModal.propTypes = {
  promoMessage: objectOf(any).isRequired,
  modalOpen: bool.isRequired,
  closeModal: func.isRequired,
}

export default CreditModal
